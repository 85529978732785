import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-logo">
        <img src="/logo-sebatian.png" alt="Sebatian Logo" className="logo" />
      </div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="/projects">projects</Link></li>
        </ul>
      </nav>
      <div className="header-logo">
        <img src="/logo-validator.png" alt="Validator Logo" className="validator-logo" />
      </div>
    </header>
  );
};

export default Header;
