import React from 'react';
import { useParams } from 'react-router-dom';

const Project = () => {
  const { projectName } = useParams();

  const projectDetails = {
    airchains: {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/Airchain',
      explorer: 'https://explorer.sebatian.org/Airchains',
      website: 'https://www.airchains.io/',
      logo: '/logos/airchains-logo.png'
    },
    initia: {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/Initia',
      explorer: 'https://scan.testnet.initia.xyz/initiation-1',
      website: 'https://initia.xyz/',
      logo: '/logos/initia-logo.png'
    },
    '0g': {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/0g',
      explorer: 'https://explorer.sebatian.org/0g',
      website: 'https://0g.ai/',
      logo: '/logos/0g-logo.png'
    },
    pactus: {
      guide: 'https://github.com/Sebatian1/Mainnet/tree/main/Pactus',
      explorer: 'https://pacviewer.com',
      website: 'https://pactus.org/',
      logo: '/logos/pactus-logo.png'
    },
    tanssi: {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/Tanssi',
      explorer: 'https://dancebox.subscan.io/',
      website: 'https://www.tanssi.network/',
      logo: '/logos/tanssi-logo.png'
    },
    nubit: {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/Nubit',
      explorer: 'https://explorer.nubit.org/',
      website: 'https://nubit.org/',
      logo: '/logos/nubit-logo.png'
    },
    dill: {
      guide: 'https://github.com/Sebatian1/Testnet/tree/main/Dill',
      explorer: 'https://andes.dill.xyz/',
      website: 'https://dill.xyz/',
      logo: '/logos/dill-logo.png'
    },
    // Add more projects here
  };

  const project = projectDetails[projectName.toLowerCase()];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div>
      <img src={project.logo} alt={`${projectName} Logo`} className="project-logo" />
      <h1>{projectName.charAt(0).toUpperCase() + projectName.slice(1)}</h1>
      <ul>
        <li><a href={project.guide} target="_blank" rel="noopener noreferrer">Guide</a></li>
        <li><a href={project.explorer} target="_blank" rel="noopener noreferrer">Explorer</a></li>
        <li><a href={project.website} target="_blank" rel="noopener noreferrer">Website</a></li>
      </ul>
    </div>
  );
};

export default Project;
