import React from 'react';
import { Link } from 'react-router-dom';

const Projects = () => {
  return (
    <div>
      <h1>Our Projects</h1>
      <ul>
        <li>
          <Link to="/Projects/Airchains">Airchains</Link>
        </li>
        <li>
          <Link to="/Projects/Initia">Initia</Link>
        </li>
        <li>
          <Link to="/Projects/0g">0g</Link>
        </li>
        <li>
          <Link to="/Projects/Pactus">Pactus</Link>
        </li>
        <li>
          <Link to="/Projects/Tanssi">Tanssi</Link>
        </li>
        <li>
          <Link to="/Projects/Nubit">Nubit</Link>
        </li>
        <li>
          <Link to="/Projects/Dill">Dill</Link>
        </li>
        {/* Add more projects here */}
      </ul>
    </div>
  );
};

export default Projects;
