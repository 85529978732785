import React from 'react';

const About = () => {
  return (
    <div>
      <h1>About Sebatian Validator</h1>
      <p>Information about the validator and its mission.</p>
    </div>
  );
};

export default About;
