import React from 'react';
import './Home.css';

const projectDetails = {
  Airchains: {
    name: 'Airchains',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/Airchain',
    explorer: 'https://explorer.sebatian.org/Airchains',
    logo: '/logos/airchains-logo.png'
  },
  Initia: {
    name: 'Initia',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/Initia',
    explorer: 'https://scan.testnet.initia.xyz/initiation-1',
    logo: '/logos/initia-logo.png'
  },
  '0g': {
    name: '0g',
    guide: 'https://github.com/Sebatian1/Cosmos/tree/main/0g',
    explorer: 'https://explorer.sebatian.org/0g',
    logo: '/logos/0g-logo.png'
  },
  Pactus: {
    name: 'Pactus',
    guide: 'https://github.com/Sebatian1/Mainnet/tree/main/Pactus',
    explorer: 'https://pacviewer.com',
    logo: '/logos/pactus-logo.png'
  },
  Tanssi: {
    name: 'Tanssi',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Tanssi',
    explorer: 'https://dancebox.subscan.io',
    logo: '/logos/tanssi-logo.png'
  },
  Nubit: {
    name: 'Nubit',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Nubit',
    explorer: 'https://explorer.nubit.org',
    logo: '/logos/nubit-logo.png'
  },
  Dill: {
    name: 'Dill',
    guide: 'https://github.com/Sebatian1/Testnet/tree/main/Dill',
    explorer: 'https://andes.dill.xyz',
    logo: '/logos/dill-logo.png'
  },
};

const Home = () => {
  return (
    <div className="home">
      <div className="header">
        <img src="/sebatian-logo.png" alt="Sebatian Logo" className="main-logo" />
        <h1>Welcome to Sebatian Validator</h1>
      </div>
      <p className="subtitle">Sebatian is a reliable Proof-of-Stake validator providing secure staking services</p>
      <div className="projects">
        {Object.keys(projectDetails).map((key) => (
          <div className="project-card" key={key}>
            <div className="project-header">
              <img src={projectDetails[key].logo} alt={`${projectDetails[key].name} Logo`} className="project-logo" />
              <h2>{projectDetails[key].name}</h2>
            </div>
            <div className="links">
              <a href={projectDetails[key].guide} target="_blank" rel="noopener noreferrer">Guide</a>
              <a href={projectDetails[key].explorer} target="_blank" rel="noopener noreferrer">Explorer</a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
